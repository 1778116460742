

























import { FormHelper, Toast } from '@/mixins'
import { Component, Mixins } from 'vue-property-decorator'
import { Admin } from '@/api'

@Component
export default class UnbanModal extends Mixins(FormHelper, Toast) {
  isOpen = false
  userInfos: UserBanInfo = {} as UserBanInfo
  reason = ''

  $refs!: {
    unbanForm: HTMLFormElement
  }
  
  async mounted() {
    this.$root.$on('openUnbanModal', (userInfos) => {
      this.isOpen = true
      this.userInfos = userInfos
    })
  }

  async unban(e) {
    e.preventDefault() // disable auto closing on OK

    const isFormValid = await this.$refs.unbanForm.validate()

    if (!isFormValid) return

    try {
      await Admin.unbanUser(this.userInfos.id, this.reason)

      this.showSuccessToast({
        title: 'Successfully unbanned!',
        message: `${this.userInfos.username} has been unbanned.`
      })
      this.$root.$emit('refreshUserList')
      this.isOpen = false
    } catch(e) {
      console.error('Error when trying to unban user', e)
    }
  }
}
